<template>
    <div>
        <apexchart ref="graphical_chart" type="radialBar" height="300" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    name: 'GraphicalChart',
    components: { apexchart: VueApexCharts },

    props: ['graphical'],

    data(){
        return{
            series: [],
            chartOptions: {
                colors: [this.graphical.color],
                chart: {
                    height: 300,
                    type: 'radialBar',
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: ['#000'],
                    width: 2,
                    dashArray: 0,      
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 15,
                            size: '65%',
                            image: this.graphical.image,
                            imageWidth: 90,
                            imageHeight: 90,
                            imageClipped: false
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                color: '#fff',
                                offsetY: 130,
                                fontSize: '18px',
                                fontFamily: 'Montserrat',
                            }
                        }
                    }
                },
            },

        }
    },

    methods: {
        updateSeries() {
            this.series = [this.graphical.data];
        },
    },

    mounted() {
        this.updateSeries();
    },

    watch: {
        graphical: function(){
            this.updateSeries();
        }
    }
}
</script>

<style>

</style>