<template>
    <div>
        <apexchart ref="pie_chart" type="donut" height="450" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    name: 'PieChart',
    components: { apexchart: VueApexCharts },

    props: ['pie'],

    data(){
        return{
            series: [],
            chartOptions: {
                labels: [],
                chart: {
                    type: 'donut',
                },
                colors: ['#1aab7a', '#eeaaaa', '#6680ff', '#ffb600', '#bada55', '#16e4ff', '#ff5c00', '#cc5599', '#24000f', '#ffb224', '#00d100', '#6680ff', '#ffb600', '#bada55', '#16e4ff', '#ff5c00', '#cc5599', '#FF00FF', '#bf00ff', '#a633cc', '#ff7b00'],
                legend: {
                    position: 'left',
                    labels: {
                        colors: ['#000','#000','#000','#000','#000','#000','#000','#000'],
                        useSeriesColors: false
                    },
                },
                dataLabels: { 
                    enabled: true,
                    formatter: function (val, opt) {
                        return Number(val.toFixed(2))+"%"
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '22px',
                                    color: '#dfsda',
                                    fontFamily: 'Verdana',
                                    offsetY: -10
                                },
                                value: {
                                    show: true,
                                    color: '#000',
                                    fontFamily: 'Verdana',
                                    fontSize: '35px',
                                    offsetY: 16,
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                                total: {
                                    show: true,
                                    label: 'Total',
                                    fontFamily: 'Verdana',
                                    color: '#000',
                                    fontSize: '40px',
                                    formatter: function (w) {
                                        return w.globals.seriesTotals.reduce((a, b) => {
                                            return a + b
                                        }, 0)
                                    }
                                }
                            }
                        }
                    }
                },
            },

        }
    },

    methods: {
        updateSeries() {
            this.series = this.pie.data;
            this.chartOptions.labels = this.pie.labels;
            this.$refs.pie_chart.updateSeries(this.series, true);
            this.$refs.pie_chart.updateOptions(this.chartOptions, true);
        },
    },

    mounted() {
        this.updateSeries();
    },

    watch: {
        pie: function(){
            this.updateSeries();
        }
    }
}
</script>

<style>

</style>