<template>
    <div id="chart">
        <apexchart ref="bar_chart" type="bar" height="450" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
    name: 'BarChart',
    components: { apexchart: VueApexCharts },

    props: ['bar', 'totalSum'],

    data(){
        return{
            series: [{
                data: [],
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 450
                },
                fill: {
                    /*colors: ['#FFC300']*/
                },
                /* plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    },
                }, */
                plotOptions: {
                    bar: {
                        horizontal: true,
                        distributed: true,
                        // startingShape: "rounded",
                        // endingShape: "rounded",
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        colors: {
                            backgroundBarColors: ["#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0","#D0D0D0"],
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 0
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val) => {
                        var percent = (val/this.totalSum)*100 > 0 ? (val/this.totalSum)*100 : 0
                        var str = val > 0 ? val + ' (' + Number(percent.toFixed(2)) + '%)' : 0
                        return str
                    },
                    style: {
                        colors: ['#eee']
                    },
                },
                grid: {
                    show: false,
                },
                xaxis: {
                    labels:{
                        show: true,
                        style: {
                            colors: ['#000'],
                            fontFamily: 'Verdana',
                        },
                    }
                },
                yaxis: {
                    labels:{
                        show: true,
                        style: {
                            colors: ['#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000'],
                            fontFamily: 'Verdana',
                        },
                    }
                },
                colors: ['#1aab7a', '#eeaaaa', '#6680ff', '#ffb600', '#bada55', '#16e4ff', '#ff5c00', '#cc5599', '#24ffff', '#ffb224', '#00d100', '#6680ff', '#ffb600', '#bada55', '#16e4ff', '#ff5c00', '#cc5599', '#FF00FF', '#bf00ff', '#a633cc', '#ff7b00'],
                legend: {
                    show: false
                }
            },            
        }
    },

    methods: {
        updateSeries() {
            this.series[0].data = this.bar;
            this.$refs.bar_chart.updateSeries(this.series, true);
        },
    },

    mounted() {
        this.updateSeries();
    },

    watch: {
        bar: function(){
            this.updateSeries();
        }
    }

}
</script>

<style>

</style>