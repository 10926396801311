<template>

	<div>
		<!-- Table Container Card -->
		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb :items="breadCrumb()" />
				</div>
			</div>
		</template>

		<b-tabs pills justified class="mt-2">
			<b-tab title="Overview" lazy @click="resetStats();">
				<OverviewComponent />
			</b-tab>
			<b-tab title="Statistics" lazy @click="resetOverview();">
		<b-card no-body>
			<b-card-body>
				<b-row>
					<b-col cols="12" md="4" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
						<v-select
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="sites"
							label="site_name"
							class="w-100"
							placeholder="Project Site"
							@input="allLocations(); getChartData(); getheaderImages(); reset();"
							v-model="site"
							:clearable="false"
						>
							<template #selected-option="{ site_name }">
								<span v-if="site_name.length < 18">{{site_name}}</span>
								<span v-if="site_name.length >= 18">{{site_name.substring(0, 18) + '...'}}</span>
							</template>
						</v-select>
					</b-col>
					
					<b-col cols="12" md="4" class="mb-md-0 mb-0 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
						<b-form-group class="tab_mobile_margin_bottom_zero">
							<v-select v-model="location" @input="getChartData();" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="locations" placeholder="Select Location" :clearable="false"/>
						</b-form-group>
					</b-col>

					<b-col cols="12" md="4" class="mb-md-0 mb-0 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
						<b-form-group class="tab_mobile_margin_bottom_zero">
							<v-select v-model="filter_type" @input="getChartData();" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="filter_types" placeholder="Select Filter Type" :clearable="false"/>
						</b-form-group>
					</b-col>
	
				<!-- </b-row>

				<b-row class="mt-1"> -->
					
					<b-col cols="12" md="4" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
						<v-select
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="months"
							class="w-100"
							placeholder="Month"
							@input="getDate(); getChartData();"
							v-model="month"
							:clearable="false"
						/>
					</b-col>

					<b-col cols="12" md="4" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
						<v-select
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="yearData"
							class="w-100"
							placeholder="Year"
							@input="getDate(); getChartData();"
							v-model="year"
							:clearable="false"
						/>
					</b-col>

				</b-row>

			<!-- </b-card-body>
    	</b-card>

		<b-card no-body>
			<b-card-body> -->
				<b-row>
					<!-- v-if="site && location && filter_type && month && year" -->
					<b-col cols="12" md="6" class="mobile_tab_max_width_flex">
						<!-- <b-form-group class="mb-0">
							<v-select v-model="chart_type" 
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
							label="name" 
							:options="chart_types" 
							placeholder="Chart Type" 
							@input="getChartData()"/>
						</b-form-group> -->

						<b-form-group label="" class="pt-2 custom_radio_btn tab_mobile_padding_top_zero tab_mobile_margin_bottom_zero">
							<b-form-radio-group
								id="chart_type"
								class="tab_mobile_full_radio"
								v-model="chart_type"
								:options="chart_types"
								@input="getChartData()"
							></b-form-radio-group>
						</b-form-group>
					</b-col>
					
					<b-col cols="12" md="6" class="tab_mobile_float_left float-right mobile_tab_max_width_flex pt-2" v-if="(site && location && filter_type && month && year && chart_type) && checkPermission($route.name, 'Export PDF')">
                        <b-button title="Export PDF" variant="warning" @click="exportPDF();" class="tab_mobile_float_left float-right">
							Export PDF
                        </b-button>

						<!-- Print PDF block -->
						<vue-html2pdf
							:show-layout="false"
							:float-layout="true"
							:enable-download="true"
							:preview-modal="false"
							:paginate-elements-by-height="1398"
							:filename="chart_type"
							:pdf-quality="3"
							:manual-pagination="false"
							pdf-format="a4"
							:pdf-margin="10"
							pdf-orientation="landscape"
							pdf-content-width="1065px"
							@progress="onProgress($event)"
							@beforeDownload="beforeDownload($event)"
							ref="html2Pdf"
						>
							<section slot="pdf-content">
							<div class="my-pdf bg-white">
								<b-row v-if="organization_logo || site_logo">
									<b-col cols="6">
										<img v-if="site_logo" :src="site_logo" alt="Logo" width="110" height="110" loading="eager"/>
									</b-col>
									<b-col cols="6">
										<img v-if="organization_logo" :src="organization_logo" alt="Logo" width="110" height="110" class="float-right" loading="eager"/>
									</b-col>
								</b-row>

								<b-row v-if="organization_logo || site_logo">
									<b-col cols="12" class="text-center"><strong><span style="color: #000 !important; font-size: 15px;">Feedback Panel Report</span></strong></b-col>
								</b-row>
								<b-row style="color: #000 !important; font-size: 12px !important;" v-if="organization_logo || site_logo" class="pb-2 pt-1">
									<b-col cols="3" class="my-font"><strong>Project Site</strong></b-col>
									<b-col cols="3" class="my-font">: {{site.site_name}}</b-col>
									<b-col cols="3" class="my-font"><strong>Period</strong></b-col>
									<b-col cols="3" class="my-font">: {{month + ' ' +year}}</b-col>
									
									<b-col cols="3" class="my-font"><strong>Location</strong></b-col>
									<b-col cols="3" class="my-font">: {{location.name}}</b-col>
									<b-col cols="3" class="my-font"><strong>Generated By</strong></b-col>
									<b-col cols="3" class="my-font">: {{this.$store.getters.currentUser.full_name}}</b-col>
									
									<b-col cols="3" class="my-font"><strong>Filter</strong></b-col>
									<b-col cols="3" class="my-font">: {{filter_type}}</b-col>
									<b-col cols="3" class="my-font"><strong>Type</strong></b-col>
									<b-col cols="3" class="my-font">: {{chart_type}}</b-col>
								</b-row>

								<b-row v-if="chart_type == 'Graphical Chart' && graphicalData">
									<b-col cols="3" class="text-center" v-for="(gd, ind) in graphicalData" :key="ind">
										<div>
											<GraphicalChartPDF :graphical="{data: gd.percentage, image: gd.image, color: gd.color}"/>
											<span class="text-center" style="color: #000 !important; font-size: 12px !important;">{{gd.label}} - {{gd.data}}</span>
										</div>
									</b-col>
								</b-row>
								<div v-else-if="chart_type == 'Bar Chart' && barData">
									<BarChartPDF :bar="barData" :totalSum="totalSum"/>
								</div>
								<div v-else-if="chart_type == 'Pie Chart'  && pieData">
									<PieChartPDF :pie="pieData"/>
								</div>
								<h5 v-else class="text-center"><!-- No Graph Selected --></h5>

								<b-row v-if="main_logo">
									<b-col cols="12">
										<img v-if="main_logo" :src="main_logo" alt="Logo" width="110" loading="eager"/>
									</b-col>
								</b-row>
							</div>

							</section>
						</vue-html2pdf>

                    </b-col>

				</b-row>

			</b-card-body>
		</b-card>

		<!-- <b-card no-body class="mb-0"> -->
      		<div>
				<div v-if="site && location && filter_type && month && year && chart_type">
					<b-row v-if="chart_type == 'Graphical Chart' && graphicalData">
						<b-col cols="12" sm="6" md="4" xl="3" class="text-center" v-for="(gd, ind) in graphicalData" :key="ind">
							<b-card>
								<GraphicalChart :graphical="{data: gd.percentage, image: gd.image, color: gd.color}"/>
								<h4 class="text-center">{{gd.label}} - {{gd.data}}</h4>
							</b-card>
						</b-col>
					</b-row>
					<b-card v-else-if="chart_type == 'Bar Chart' && barData">
						<BarChart :bar="barData" :totalSum="totalSum"/>
					</b-card>
					<b-card v-else-if="chart_type == 'Pie Chart'  && pieData">
						<PieChart :pie="pieData"/>
					</b-card>
				</div>
				<h5 v-else class="text-center"><!-- No Graph Selected --></h5>
			</div>
		<!-- </b-card> -->
			</b-tab>
		</b-tabs>

	</div>
</template>

<script>
	import {
		BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,BFormGroup,
		BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BCardBody, BFormSelectOption,
		BFormSelect, VBTooltip, BFormTextarea, BForm, BFormRadio, BFormDatepicker, BBreadcrumb, BFormRadioGroup,
		BTabs, BTab
	} from 'bootstrap-vue'
	import vSelect from 'vue-select'
	import { POST_API } from "../../../store/actions.type"
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';

	//you need to import the CSS manually (in case you want to override it)
	import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
	import moment from "moment-timezone";
	import VueApexCharts from 'vue-apexcharts'
	import { $themeColors } from '@themeConfig'
	import flatPickr from 'vue-flatpickr-component'
	import GraphicalChart from './GraphicalChart.vue';
	import BarChart from './BarChart.vue';
	import PieChart from './PieChart.vue';
	import GraphicalChartPDF from './GraphicalChartPDF.vue';
	import BarChartPDF from './BarChartPDF.vue';
	import PieChartPDF from './PieChartPDF.vue';
	import VueHtml2pdf from 'vue-html2pdf'
	import Bus from "../../../event-bus";
	import OverviewComponent from './OverviewComponent.vue';

	export default {
		components: {
			DatePicker, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, 
			BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, vSelect, BFormSelect, 
			BFormSelectOption, VueApexCharts, BFormTextarea, BAlert, BFormGroup, BForm, flatPickr, 
			BFormRadio, BFormDatepicker, BBreadcrumb, GraphicalChart, BarChart, PieChart, 
			GraphicalChartPDF, BarChartPDF, PieChartPDF, VueHtml2pdf, BFormRadioGroup, 
			BTabs, BTab, OverviewComponent
		},

		directives: {
			'b-tooltip': VBTooltip,
		},

		data() {
			return {
				totalSum: null,

				sites: [],
				site: null,

				locations: [],
				location: null,

				filter_types: ['QR', 'Feedback Panel','Web', 'All'],
				filter_type: null,

				chart_types: ['Bar Chart', 'Pie Chart', 'Graphical Chart'],
				chart_type: 'Bar Chart',

				// date vars
				yearData:[],
				year:moment().format('YYYY'),
				months:['January', 'February', 'March', 'April', 'May', 'June', 'July ', 'August', 'September', 'October', 'November', 'December'],
				month:moment().format('MMMM'),
				start: null,
				end: null,

				// chart data
				barData:null,
				graphicalData:null,
				pieData:null,

				organization_logo : '',
                site_logo : '',
                main_logo : '',
			}
		},
		methods : {
			reset(){
				this.location = null
				this.filter_type = null
				// this.chart_type = null
			},

			getChartData(){
				if(this.site && this.location && this.filter_type && this.month && this.year){

					return this.$store.dispatch(POST_API, {
						data:{
							site: this.site ? this.site._id : null,
							location: this.location ? this.location._id : null,
							filter_type: this.filter_type,
							chart_type: this.chart_type,
							start: this.start,
							end: this.end
						},
						api: '/api/feedback-panel-chart'
					})
					.then(() => {
						if (this.$store.getters.containsErrors) {
							this.error_message = this.$store.getters.getErrors;
						} else {
							if(this.chart_type == "Bar Chart"){
								this.barData = this.$store.getters.getResults.data
								this.totalSum = this.$store.getters.getResults.totalSum
							}
							else if(this.chart_type == "Pie Chart"){
								this.pieData = {
									data : this.$store.getters.getResults.data.data,
									labels : this.$store.getters.getResults.data.labels
								}
							}
							else if(this.chart_type == "Graphical Chart"){
								this.graphicalData = this.$store.getters.getResults.data
							}
						}
					});
				
				}			
			},

			allSites(){
				return this.$store.dispatch(POST_API, {
					data:{
						role:this.$store.getters.currentUser.role,
					},
					api: '/api/all-sites'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
					} else {
						this.sites = this.$store.getters.getResults.data;

						if (this.sites.length == 1 ) {
							this.site = this.sites[0];
						}

						return this.sites;
					}
				});
			},

			allLocations(){
				this.location = [];
                return this.$store.dispatch(POST_API, {
                    data:{
                        site: this.site ? this.site._id : null
                    },
                    api: '/api/all-locations'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.locations = data;
                        return this.locations;
                    }
                });
            },

			getDate(){
				var date = null;
				if (this.month == null && this.year == null) {
					this.start = null;
					this.end = null;
				}else if(this.month == null && this.year != null){
					date = moment.utc().startOf('month').format('MM-DD');
					var year = this.year;
					date =  year.toString() + '-'+ date;
					date = moment.utc(date).startOf('year').format('YYYY-MM-DD');
					this.start = date;
					this.end = moment(date).endOf('year').format('YYYY-MM-DD');
				}else if(this.month != null && this.year == null){
					var year = moment.utc().startOf('month').format('YYYY');
					date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
					date = moment.utc(date).startOf('month').format('YYYY-MM-DD');
					this.start = date;
					this.end = moment(date).endOf('month').format('YYYY-MM-DD');
				}else{
					var year = this.year.toString();
					date = year +'-' + moment().month(this.month).startOf('month').format('MM-DD');
					date = moment.utc(date).startOf('month').format('YYYY-MM-DD');
					this.start = date;
					this.end = moment(date).endOf('month').format('YYYY-MM-DD');
				}
			},

			calYear(){
				var year = moment().format('YYYY');
				var yearData = [];
				var start = parseInt(year) - 2;
				var end =  parseInt(year) + 5;

				for (var i = start ; i <= end; i++) {
					yearData.push(i);
				}

				this.yearData = yearData;
			},

			breadCrumb(){
				var item = [{
					to:{name:'client-dashboard'},
					text: 'Dashboard',
				},{
					to:null,
					text: 'Reports',
				},{
					to:null,
					text:'Feedback Panel',
					active:true
				}];
				return item;
			},
			
			// Export PDF functions
				exportPDF() {
					Bus.$emit('showLoader');
					this.$refs.html2Pdf.generatePdf();
				},

				onProgress(event) {
					if(event == 100){
						Swal.fire({
							position : 'center',
							icon     : 'success',
							title    : 'PDF Downloaded.',
							timer    : 1500,
							showConfirmButton : false
						});
						Bus.$emit('hideLoader');
					}
				},

				beforeDownload ({ html2pdf, options, pdfContent }) {
					options.margin = .3;
					options.useCORS = true;

					html2pdf().set(options);
				},

				getheaderImages(){
					return this.$store.dispatch(POST_API, {
						data:{
							site : this.site ? this.site._id : null
						},
						api: '/api/feedback-panel-logo'
					})
					.then(() => {
						if (this.$store.getters.containsErrors) {
							this.error_message = this.$store.getters.getErrors;
						} 
						else {
							this.organization_logo = this.$store.getters.getResults.data.organization_logo;
							this.site_logo = this.$store.getters.getResults.data.site_logo;
							this.main_logo = this.$store.getters.getResults.data.main_logo;
						}
					});
				},

			resetStats(){
				this.totalSum    = null;
				this.site 		 = null;
				this.locations 	 = [];
				this.location    = null;
				this.filter_type = null;
				this.chart_type  = 'Bar Chart';
				this.year   	 = moment().format('YYYY');
				this.month       = moment().format('MMMM');
				this.barData	 = null;
				this.graphicalData = null;
				this.pieData 	 = null;

				this.allSites().then(()=> {
					this.allLocations()
				});
			},

			resetOverview(){
				Bus.$emit('reset-filter');
			},
		},

		mounted(){
			this.allSites().then(()=> {
				this.allLocations()
			});
			this.calYear();
			this.getDate();
		}
	
	}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

	// pdf css
	.my-pdf{
		font-family: Verdana, Helvetica, Sans-Serif !important;
		color: black !important;
	}
	.my-font{
		font-size: 11px !important;
	}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
